<script setup lang="ts">
import { defineProps, computed } from 'vue';
import BRFCSLayout from '@/Layouts/BRFCSLayout.vue';
import HomepageHero from '@/Components/HomepageHero/HomepageHero.vue';
import Block from '@/Components/Common/Block.vue';
import PodcastCard from '@/Components/Article/PodcastCard.vue';
import TextLink from '@/Components/Common/TextLink.vue';
import { ChevronRightIcon } from '@heroicons/vue/24/outline';
import { getMatchStatus } from '@/common/helpers/football-helpers';
import ArticleCardsBlock from '@/Components/Article/ArticleCardsBlock.vue'

const props = defineProps<{
  featuredArticles: ArticleType[];
  moreArticles: ArticleType[];
  fkhArticles: ArticleType[];
  fixtures: MatchType[];
  results: MatchType[];
  podcasts: ArticleType[];
  sharpeArticles: ArticleType[];
}>();

const sortedFixtures = computed(() => {
  return props.fixtures.sort((a, b) => {
    return Date.parse(a.fixture.date) - Date.parse(b.fixture.date);
  }).filter((fixture) => getMatchStatus(fixture.fixture) !== 'C');
});

const sortedResults = computed(() => {
  return props.results.sort((a, b) => {
    return Date.parse(b.fixture.date) - Date.parse(a.fixture.date);
  });
});

</script>

<template>
  <BRFCSLayout>
    <HomepageHero :articles="featuredArticles" :fixtures="sortedFixtures" :results="sortedResults" />
    <ArticleCardsBlock title="Articles" linkTo="/articles" :articles="moreArticles" />
    <Block title="4000 Holes Podcast">
      <template v-slot:blockLink>
        <TextLink :linkTo="route('podcasts.list')">
          Discover More Episodes
          <template #icon>
            <ChevronRightIcon />
          </template>
        </TextLink>
      </template>
      <section class="u-content-grid">
        <PodcastCard v-for="(podcast, index) in podcasts" :key="podcast.id" :article="podcast"
          :modifierClass="index > 3 ? 'o-podcast-card--hidden' : ''" />
      </section>
    </Block>
    <ArticleCardsBlock title="4000 Holes Articles" :linkTo="route('articles.byCategory', ['4000 Holes'])"
      :articles="fkhArticles" />
    <ArticleCardsBlock title="Guest Columns" :linkTo="route('articles.byAuthor', ['guests'])"
      :articles="sharpeArticles" />
    <!-- <Block title="Latest from the forum">
            <template v-slot:blockLink>
                <TextLink linkTo="/articles">
                    Visit the Forum
                    <template #icon>
                        <ChevronRightIcon />
                    </template>
                </TextLink>
            </template>
        </Block> -->
    <!-- <Block title="Till The..."></Block> -->
  </BRFCSLayout>
</template>
