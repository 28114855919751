<script setup lang="ts">
import 'vue3-carousel/dist/carousel.css';
import { ref, onMounted } from 'vue';
import { Carousel, Slide, Pagination } from 'vue3-carousel';
import MatchCard from './MatchCard.vue';
import Countdown from '@/Components/Common/Countdown.vue';
import { getMatchStatus, getOpponentLogoPath, getOpponentName, isHomeFixture, getMatchCentreURL } from '@/common/helpers/football-helpers';

const props = defineProps<{
  fixtures: MatchType[];
  results: MatchType[];
}>();

// Work out which match to scroll to, if a result match started within the last 24 hours, scroll to that match
let matchToScrollTo = 3; // Default to the next match
if (props.results.length) {
  const lastResult = props.results[0];
  const lastResultDate = new Date(lastResult.fixture.date);
  const now = new Date();
  const diff = now.getTime() - lastResultDate.getTime();
  const diffHours = diff / (1000 * 60 * 60);
  if (diffHours < 24) {
    matchToScrollTo = 2;
  }
}

const myCarousel = ref(null)
onMounted(() => {
  setTimeout(() => {
    myCarousel.value.slideTo(matchToScrollTo)
  }, 1000)
})

const resentResults = props.results.reverse();

const sliderItemsToShow = ref(window?.innerWidth > 1200 ? 1.75 : 1.25);

</script>

<template>
  <section class="w-matches-hero">
    <header class="w-matches-hero__header" v-if="fixtures.length && getMatchStatus(fixtures[0].fixture) == 'U'">
      <div class="w-matches-hero__next-match">
        <span class="w-matches-hero__title">Next Match:</span>
        <a :href="getMatchCentreURL(fixtures[0])" class="w-matches-hero__next-match-link">
          <img :src="getOpponentLogoPath(fixtures[0])" :alt="getOpponentName(fixtures[0])"
            class="w-matches-hero__opponent-logo" />
          {{ isHomeFixture(fixtures[0]) ? '(H)' : '(A)' }}
        </a>
      </div>
      <Countdown :countdownTo="fixtures[0].fixture.date" />
    </header>
    <div class="w-matches-hero__main">
      <Carousel :items-to-show="sliderItemsToShow" :snap-align="'center'" ref="myCarousel">

        <Slide key="0">
          <a href="/results" class="w-matches-hero__cta">
            <span class="w-matches-hero__cta-subtitle">View All</span>
            <span class="w-matches-hero__cta-title">Results</span>
          </a>
        </Slide>
        <Slide key="1" v-if="resentResults[1]">
          <MatchCard :match="resentResults[1]" />
        </Slide>
        <Slide key="2" v-if="resentResults[0]">
          <MatchCard :match="resentResults[0]" />
        </Slide>
        <Slide key="3" v-if="fixtures[0]">
          <MatchCard :match="fixtures[0]" />
        </Slide>
        <Slide key="4" v-if="fixtures[1]">
          <MatchCard :match="fixtures[1]" />
        </Slide>
        <Slide key="5" v-if="fixtures[2]">
          <MatchCard :match="fixtures[2]" />
        </Slide>
        <Slide key="6" v-if="fixtures.length">
          <a href="/fixtures" class="w-matches-hero__cta">
            <span class="w-matches-hero__cta-subtitle">View All</span>
            <span class="w-matches-hero__cta-title">Fixtures</span>
          </a>
        </Slide>
        <template #addons>
          <pagination />
        </template>
      </Carousel>
    </div>
  </section>
</template>

<style scoped lang="scss">
@use "#/utilities/mixins" as m;

.w-matches-hero {
  $this: &;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 3.2rem;
  position: relative;
  padding: 3.2rem 0;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: radial-gradient(circle at 50% 75%, var(--g-color-primary), transparent);
    opacity: .4;
  }

  &__header {
    @include m.p1-bold;
    text-transform: uppercase;
    margin: 0;
    padding: 0;
    text-align: center;
    display: flex;
    gap: 1.6rem;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: var(--z-index-one);
  }

  &__next-match {
    display: flex;
    flex-direction: column;
  }

  &__next-match-link {
    @include m.link-reset;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .4rem;
  }

  &__opponent-logo {
    width: 2.4rem;
    height: 2.4rem;
    object-fit: contain;
  }

  &__cta {
    width: 90%;
    height: 75%;
    margin: 1rem;
    display: flex;
    flex-direction: column;
    /* gap: .4rem; */
    /* padding: 4.8rem; */
    border-radius: 1.2rem;
    justify-content: center;
    align-items: center;
    background: var(--g-gradient-primary-secondary);
    @include m.link-reset;
    box-shadow: rgba(0, 0, 0, 0.4) 0 .4rem 1.2rem;
    /* position: relative; */
    overflow: hidden;
    transition: transform ease-out 0.2s;
    gap: 1.2rem;
    position: relative;
    padding: 2.8rem 0;

    @include m.hover {
      transform: scale(1.02);
    }

    /* &::after {
            content: '';
            position: absolute;
            bottom: -13rem;
            right: -12rem;
            width: 30rem;
            height: 30rem;
            background-image: url('/images/logos/blue-rose.png');
            background-size: cover;
            background-repeat: no-repeat;
            opacity: 0.05;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: radial-gradient(circle at 50% 75%, var(--g-color-primary), transparent);
            opacity: .4;
        } */

    &__header {
      @include m.p1-bold;
      text-transform: uppercase;
      margin: 0;
      padding: 0;
      text-align: center;
      display: flex;
      gap: 1.6rem;
      align-items: center;
      justify-content: center;
      position: relative;
      z-index: var(--z-index-one);
      min-height: 6rem;
    }

    &__cta {
      width: 90%;
      height: 75%;
      margin: 1rem;
      display: flex;
      flex-direction: column;
      gap: .4rem;
      padding: 4.8rem;
      border-radius: 1.2rem;
      justify-content: center;
      align-items: center;
      background: var(--g-gradient-primary-secondary);
      @include m.link-reset;
      box-shadow: rgba(0, 0, 0, 0.4) 0 .4rem 1.2rem;
      position: relative;
      overflow: hidden;
      transition: transform ease-out 0.2s;
    }

    &-subtitle {
      @include m.h5;
      color: var(--g-color-system-white);
    }

    &-title {
      @include m.h3-bold;
      color: var(--g-color-system-white);
      display: flex;
      align-items: center;
      gap: .8rem;
      text-transform: uppercase;
    }
  }

  .o-match-hero-card {
    min-width: 90%;
  }

  @media (width < m.$desktop) {
    gap: 2.4rem;

    &__main {
      gap: 1.6rem;
    }
  }

  @media (width < m.$tablet) {
    gap: 1.6rem;

    &__main {
      gap: .8rem;
    }
  }
}

.carousel {
  --vc-clr-primary: #000;
  --vc-clr-secondary: #090f207f;
  --vc-clr-white: #ffffff;
  --vc-icn-width: 1.2em;
  --vc-nav-width: 30px;
  --vc-nav-height: 30px;
  --vc-nav-border-radius: 0;
  --vc-nav-color: var(--vc-clr-primary);
  --vc-nav-color-hover: var(--vc-clr-secondary);
  --vc-nav-background: transparent;
  --vc-pgn-width: 4.8rem;
  --vc-pgn-height: .4rem;
  --vc-pgn-margin: .8rem;
  --vc-pgn-border-radius: 0;
  --vc-pgn-background-color: rgba(255, 255, 255, .5);
  --vc-pgn-active-color: var(--g-color-system-yellow);

  &__pagination {
    transform: skew(-25deg);
    margin-top: 2.4rem;
  }

  @media (width < m.$mobile) {
    --vc-pgn-width: 3.2rem;
    --vc-pgn-margin: .4rem;
  }
}
</style>
