<script setup lang="ts">
import Article from '@/lib/article';
import config from '@/lib/config';

interface InputProps {
  article: ArticleType;
  primary?: boolean;
  showExcerpt?: boolean;
};

const props = withDefaults(defineProps<InputProps>(), {
  primary: false,
  showExcerpt: true
});

const article: Article = new Article(props.article);
</script>

<template>
  <a :href="'/articles/' + article.slug + '/' + article.id" class="o-article-card">
    <div class="o-article-card__main">
      <span class="o-article-card__title">
        {{ article.title }}
      </span>
      <span class="o-article-card__meta">
        {{ article.published_at }}
      </span>
    </div>
    <div v-bind:id="'featured-' + article.id" class="o-article-card__image-container">
      <img class="o-article-card__image" :src="article.getImagePath('medium')" :alt="article.title" loading="lazy" />
    </div>
  </a>
</template>

<style scoped lang="scss">
@use "#/utilities/mixins" as m;

.o-article-card {
  $this: &;
  @include m.link-reset;
  padding: 1rem 0;
  display: flex;
  align-items: center;
  gap: .8rem;
  border-bottom: .1rem solid var(--g-color-gray-10);
  position: relative;

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: -2rem;
    width: 0%;
    height: 100%;
    background-color: var(--g-color-gray-10);
    transition: width ease-out 0.2s;
  }

  &__image-container {
    flex: 1 40%;
    aspect-ratio: 16/9;
    border-radius: .4rem;
    position: relative;
    z-index: var(--z-index-one);
    overflow: hidden;

    &:after {
      content: '';
      position: absolute;
      width: 0;
      height: .4rem;
      z-index: var(--z-index-two);
      transition: all ease-out 0.2s;
      transform: skew(-45deg);
      bottom: 0rem;
      right: 0rem;
      background: var(--g-gradient-primary-secondary);
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform ease-out 1s;
  }

  &__main {
    flex: 1 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    z-index: var(--z-index-one);
  }

  &__title {
    @include m.p1-bold;
    text-transform: uppercase;
    @include m.truncate-multiline(2, var(--p1-line-height))
  }

  &__meta {
    @include m.xsm;
  }

  @include m.hover {
    &:before {
      width: calc(100% + 4rem);
    }

    #{$this}__image {
      transform: scale(1.05);
    }

    #{$this}__image-container {
      &:after {
        width: 66%;
      }
    }
  }

  @media (width < m.$wide) {
    #{$this}__image-container {
      flex: 1 5rem;
    }
  }

  @media (width < m.$desktop) {
    #{$this}__image-container {
      display: none;
    }
  }

  @media (width < m.$tablet) {
    align-items: stretch;

    #{$this}__image-container {
      display: block;
      flex: 1 40%;
    }
  }
}
</style>
