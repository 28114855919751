<script setup lang="ts">
import Article from '@/lib/article';
import config from '@/lib/config';

interface InputProps {
  article: PodcastType;
  primary?: boolean;
  showExcerpt?: boolean;
  modifierClass?: string;
};

const props = withDefaults(defineProps<InputProps>(), {
  primary: false,
  showExcerpt: true,
  modifierClass: ''
});

const podcast: Article = new Article(props.article);
</script>

<template>
  <a :href="'/podcasts/' + podcast.slug + '/' + podcast.id" class="o-podcast-card" :class="modifierClass">
    <div v-bind:id="'featured-' + podcast.id" class="o-podcast-card__image-container">
      <img :src="podcast.getImagePath('medium')" :alt="podcast.title" loading="lazy" class="o-podcast-card__image" />
    </div>
    <div class="o-podcast-card__main">
      <h2 class="o-podcast-card__title">
        {{ podcast.title }}
      </h2>
      <p v-if="showExcerpt" class="o-podcast-card__excerpt">
        {{ podcast.excerpt }}
      </p>
      <span class="o-podcast-card__meta">
        {{ podcast.published_at }}
      </span>
    </div>
  </a>
</template>

<style scoped lang="scss">
@use "#/utilities/mixins" as m;

.o-podcast-card {
  $this: &;
  border-radius: .8rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  @include m.link-reset;
  width: 23%;
  margin: 0 1% 2vw;
  aspect-ratio: 7/9;
  transition: box-shadow ease-out 0.2s;
  background-color: var(--g-color-system-white);
  position: relative;

  @include m.hover {
    box-shadow: 0 14px 28px rgba(0, 0, 0, .1), 0 10px 10px rgba(0, 0, 0, .1);

    #{$this}__image {
      transform: scale(1.05);
      filter: blur(0.2rem);
    }

    #{$this}__image-container {
      &::after {
        /* opacity: .95; */
        transform: scale(1.05);
      }
    }

    #{$this}__main {
      &::before {
        width: 8rem;
      }
    }
  }

  &--hidden {
    display: none;
  }

  &__image-container {
    position: absolute;
    inset: 0;

    &::before {
      content: '';
      position: absolute;
      top: 4rem;
      left: 50%;
      width: 14rem;
      height: 9.8rem;
      transform: translateX(-50%);
      background-image: url('/images/logos/4000-holes-podcast.png');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: top center;
      /* opacity: 0.075; */
      z-index: var(--z-index-one);
      transition: all ease-out 0.2s;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url('/images/logos/4000-holes-bg.png');
      background-size: contain;
      opacity: 1;
      transition: transform ease-out 0.6s;
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform ease-out 1s, filter ease-out 0.2s;
  }

  &__main {
    padding: 1.6rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1.2rem;
    margin-top: auto;
    color: var(--g-color-system-white);
    z-index: var(--z-index-one);
    min-height: 45%;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 0rem;
      left: 1.6rem;
      width: 4rem;
      height: .2rem;
      background: var(--g-color-system-yellow);
      transition: width ease-out 0.2s;
    }
  }

  &__title {
    @include m.h4-bold;
    text-transform: uppercase;
    @include m.truncate-multiline(2, var(--h4-line-height));
  }

  &__excerpt {
    color: var(--g-color-gray-10);
    @include m.sm;
    @include m.truncate-multiline(3, var(--sm-line-height));
  }

  &__meta {
    color: var(--g-color-gray-10);
    margin-top: auto;
    @include m.sm;
  }

  @media (width < m.$desktop) {
    width: 48%;

    &__main {
      min-height: 33%;
    }
  }

  @media (width < m.$mobile) {
    width: 100%;

    &__main {
      min-height: auto;
    }
  }
}
</style>
