<script setup lang="ts">
import { computed } from 'vue'
import Button from '@/Components/Common/Button.vue'
import LoadingSpinner from '@/Components/Common/LoadingSpinner.vue'
import { AcademicCapIcon, ChartBarIcon, ChevronRightIcon, StarIcon } from '@heroicons/vue/24/outline'
import { formatDate, formatTime } from '@/common/helpers/date-helpers'
import { getCDNCompLogoPath, getCDNTeamLogoPath, getMatchCentreURL, getMatchModifierClass, getMatchOutcomeClass, getMatchStatus, getMatchThemeClasses, getMatchTime, getTeamName } from '@/common/helpers/football-helpers'

interface Props {
    match: MatchType;
}

const props = defineProps<Props>()

const matchCenterLink = computed(() => {
    return getMatchCentreURL(props.match)
})

// Works out if it's a Blackburn fixture and if they are playing at home
const blackburnFixture = computed(() => {
    let fixture = 'AWAY'
    if (props.match.teams.home.id == 67) {
        fixture = 'HOME'
    }
    return fixture
})

const outcome = computed((): string | null => {
    // Works out if it's a home win, away win or draw
    if (props.match.fixture.status.short !== 'FT') {
        return null
    }

    let homeScore = props.match.score.fulltime.home
    let awayScore = props.match.score.fulltime.away
    if (homeScore > awayScore) {
        return 'H'
    } else if (homeScore < awayScore) {
        return 'A'
    } else if (homeScore == awayScore) {
        return 'D'
    } else {
        return null
    }
})

const outcomeClass = computed(() => {
    return getMatchOutcomeClass(props.match)
})


const matchStatus = computed(() => {
    return getMatchStatus(props.match.fixture)
})

const matchPhase = computed(() => {
    const matchPhaseLookup = {
        'NS': 'Upcoming',
        '1H': 'First Half',
        'HT': 'Half Time',
        '2H': 'Second Half',
        'FT': 'Full Time',
    }

    return matchPhaseLookup[props.match.fixture.status.short]
})

const matchThemeClasses = computed(() => {
    return getMatchThemeClasses(props.match)
})

</script>

<template>
    <div
        :class="`o-match-hero-card o-match-hero-card${getMatchModifierClass(matchStatus)} u-light-theme ${outcomeClass} ${matchThemeClasses}`">
        <a :href="matchCenterLink" target="_parent" v-if="match" id="matchLink" class="o-match-hero-card__link">
            <div class="o-match-hero-card__main"
                 :class="[blackburnFixture == 'HOME' ? 'border-primary border-l-8' : '', outcomeClass]">
                <header class="o-match-hero-card__header">
          <span class="o-match-hero-card__league-name" id="leagueName">
            <span v-if="match.league.id == 667"> Pre-Season Friendly </span>
            <span v-else>
              {{ match.league.name }}
            </span>
          </span>
                    <span class="o-match-hero-card__fixture-date">
            {{ formatDate(match.fixture.date) }}
          </span>
                </header>
                <section class="o-match-hero-card__scoreboard">
                    <div class="o-match-hero-card__scoreboard-main">

                        <img :src="getCDNTeamLogoPath(match.teams.home.id)" :alt="match.teams.home.name"
                             class="o-match-hero-card__team-logo o-match-hero-card__team-logo--home" id="homeLogo" />

                        <div class="o-match-hero-card__main-container">

                            <div class="o-match-hero-card__league-logo">
                                <img :src="getCDNCompLogoPath(match.league.id)" :alt="match.league.name" style="width: 30px"
                                     id="leagueLogo" />
                            </div>

                            <div class="o-match-hero-card__ko-container" v-if="match.fixture.status.short.toLowerCase() == 'ns'">
                <span class="o-match-hero-card__ko-label" id="kickoffTime">{{
                        formatTime(match.fixture.date)
                    }}</span>
                            </div>
                            <div class=""
                                 v-else-if="match.fixture.status.short.toLowerCase() == 'tbd' || match.fixture.status.short.toLowerCase() == 'pst' || match.fixture.status.short.toLowerCase() == 'canc'">
                <span class="">
                  {{
                        matchStatus
                    }}
                </span>
                            </div>
                            <div class="o-match-hero-card__scores-container" v-else>
                                <section class="o-match-hero-card__live-status"
                                         v-if="match.fixture.status.short == '1H' || match.fixture.status.short == 'HT' || match.fixture.status.short == '2H'">
                  <span class="">
                    Live
                    <span class="">
                      <span class="">
                        <span class=""></span>
                      </span>
                    </span>
                  </span>
                                    <span class="" v-if="match.fixture.status.short == '1H' || match.fixture.status.short == '2H'">
                    - {{ getMatchTime(match.fixture.status) }}</span>
                                    <span class="" v-else>- {{ match.fixture.status.short }}</span>
                                </section>
                                <div class="o-match-hero-card__score-wrapper">
                                    <div class="o-match-hero-card__scores">
                    <span class="o-match-hero-card__score o-match-hero-card__scores--home">
                      {{ match.goals.home }}
                    </span>
                                        <span class="o-match-hero-card__score o-match-hero-card__score--away">
                      {{ match.goals.away }}
                    </span>
                                    </div>
                                    <span class="o-match-hero-card__ht-score" v-if="match.fixture.status.short !== '1H'">
                    HT: <span>{{ match.score.halftime.home }}</span>-<span>{{ match.score.halftime.away }}</span>
                    <span class="o-match-hero-card__ht-score"
                          v-if="match.score.penalty.home && match.score.penalty.away">
                      PEN: <span>{{ match.score.penalty.home }}</span>-<span>{{ match.score.penalty.away }}</span>
                    </span>
                  </span>
                                </div>
                            </div>
                        </div>

                        <img :src="getCDNTeamLogoPath(match.teams.away.id)" :alt="match.teams.away.name"
                             class="o-match-hero-card__team-logo o-match-hero-card__team-logo--away" id="awayLogo" />

                    </div>
                    <div class="o-match-hero-card__scoreboard-meta">
            <span class="o-match-hero-card__team-string">{{ getTeamName(match.teams.home) }} <span
                class="o-match-hero-card__team-string--vs">v</span> {{
                    getTeamName(match.teams.away) }}</span>
                        <span class="o-match-hero-card__venue-name">{{ match.fixture.venue.name }}</span>
                    </div>
                </section>
                <footer class="o-match-hero-card__footer" v-if="match.fixture.status.short.toLowerCase() == 'ns'">
                    <Button :linkTo="matchCenterLink" v-if="$featureFlags.delapsDerbies">
                        Play Delap's Derbies
                        <template #icon>
                            <AcademicCapIcon />
                        </template>
                    </Button>
                    <Button :linkTo="matchCenterLink" v-if="$featureFlags.predictionLeague">
                        Make Score Prediction
                        <template #icon>
                            <StarIcon />
                        </template>
                    </Button>
                    <Button :linkTo="matchCenterLink">
                        Match Centre
                        <template #icon>
                            <ChevronRightIcon />
                        </template>
                    </Button>
                </footer>
                <footer class="o-match-hero-card__footer" v-else>
                    <!-- <Button :linkTo="matchCenterLink">
                      Match Centre
                      <template #icon>
                        <ChevronRightIcon />
                      </template>
                    </Button> -->
                    <Button :linkTo="matchCenterLink">
                        Player of the Match
                        <template #icon>
                            <ChartBarIcon />
                        </template>
                    </Button>
                </footer>
            </div>
        </a>
        <div class="" v-else>
            <LoadingSpinner />
        </div>
    </div>

</template>

<style scoped lang="scss">
@use "#/utilities/mixins" as m;

.o-match-hero-card {
    border-radius: 1.6rem;
    box-shadow: rgba(0, 0, 0, 0.4) 0 .4rem 1.2rem;
    text-align: center;
    overflow: hidden;
    margin: 1rem;
    transition: transform ease-out 0.2s;

    &--live {
        --c-scoreboard-bg-override: var(--g-color-system-dark);
        --c-scoreboard-text-override: var(--g-color-system-white);
    }

    &.t-match-won {
        --c-scoreboard-bg-override: var(--g-theme-match-won);
    }

    &.t-match-lost {
        --c-scoreboard-bg-override: var(--g-theme-match-lost);
    }

    &.t-match-drawn {
        --c-scoreboard-bg-override: var(--g-theme-match-drawn);
    }

    @include m.hover {
        transform: scale(1.02);
    }

    &__link {
        @include m.link-reset;
    }

    &__main {
        display: flex;
        flex-direction: column;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        padding: 2rem 2rem 1.6rem;
        background: var(--c-competition-override, var(--g-gradient-primary-secondary));
        @include m.h6-bold;
        color: var(--g-color-system-white);
        text-transform: uppercase;
    }

    &__footer {
        padding: 2rem;
        border-top: .1rem solid var(--g-color-gray-10);
        display: flex;
        justify-content: center;
        gap: .8rem;
    }

    &__fixture-meta {
        display: flex;
        padding: 2rem;
    }

    &__live-status {
        @include m.sm-bold;
        color: var(--g-theme-match-live);
        text-transform: uppercase;
        margin-bottom: .4rem;
    }

    &__scoreboard {
        display: flex;
        flex-direction: column;
        padding: 2rem;
        gap: 1.6rem;
        justify-content: space-around;
    }

    &__scoreboard-main {
        display: flex;
        align-items: center;
        justify-content: space-around;
        gap: .8rem;

    }

    &__main-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: .8rem;
    }

    &__scoreboard-meta {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: .2rem;
    }

    &__team-string {
        @include m.h6-bold;
        color: var(--g-color-gray-80);

        &--vs {
            @include m.p1;
        }
    }

    &__venue-name {
        @include m.sm-bold;
        color: var(--g-color-gray-60);
    }

    &__team-logo {
        height: 8.8rem;
        width: 8.8rem;
        object-fit: contain;
        flex-shrink: 0;
    }

    &__ko-container {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: .8rem;
    }

    &__ko-label {
        @include m.h2-bold;
        background: var(--g-gradient-primary-secondary);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        color: #fff;
    }

    &__score-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: .8rem;
    }

    &__scores {
        display: flex;
        gap: .8rem;
        justify-content: center;
    }

    &__score {
        @include m.h2-bold;
        color: var(--c-scoreboard-text-override, var(--g-color-system-white));
        background: var(--c-scoreboard-bg-override, var(--g-color-system-dark));
        width: 4.2rem;
        height: 4.2rem;
        padding-top: .6rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: .4rem;
    }

    &__ht-score {
        @include m.sm-bold;
        color: var(--g-color-gray-60);
    }

    @media (width < m.$desktop) {
        width: 80%;
    }

    @media (width < m.$mobile) {
        &__team-logo {
            height: 6.4rem;
            width: 6.4rem;
            object-fit: contain;
        }

        &__footer,
        &__scoreboard {
            padding: 1.2rem;
        }

        &__header {
            padding: 1.6rem 1.2rem 1.2rem;
        }

        &__scoreboard {
            gap: .8rem;
        }
    }
}
</style>
