<script setup lang="ts">
import { defineProps, useSlots } from 'vue';

const slots = useSlots();

const props = defineProps<{
  linkTo: string;
}>();
// console.log(this.props.linkTo);
</script>

<template>
  <a :href="linkTo" class="o-text-link">

    <span class="o-text-link__text">
      <slot></slot>
    </span>

    <div class="o-text-link__icon" v-if="slots.icon">
      <slot name="icon"></slot>
    </div>
  </a>
</template>

<style scoped lang="scss">
@use "#/utilities/mixins" as m;

.o-text-link {
  $this: &;
  cursor: pointer;
  @include m.link-reset;
  display: inline-flex;
  gap: .4rem;
  text-align: center;
  text-decoration: none;
  justify-items: center;
  align-items: center;
  @include m.sm;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    bottom: -.1rem;
    left: 0;
    width: 100%;
    height: .1rem;
    background: var(--g-gradient-primary-secondary);
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.3s;
  }

  &__text {
    margin-top: .3rem;
  }

  &__icon {
    width: 1.2rem;
    height: 1.2rem;
  }

  @include m.hover {

    &::before {
      transform: scaleX(1);
    }

    #{$this}__icon {
      animation: wiggle 0.3s;
    }
  }
}
</style>
